<template>
    <div class="app-footer">

        <p>
            Desenvolvido pelo grupo de Projeto Integrador Univesp |
            <router-link to="/about">Sobre</router-link>
        </p>

    </div>
</template>

<style lang="scss" scoped>

.app-footer {

    border-top: 1px solid #ddd;
    text-align: center;
    margin: 0 2rem;
    padding: 1rem 0;

}

</style>